@import '../settings';

#product-shape {
  fill: #DBED64;
}
#background-image1{
    position: absolute;
    top: 0;
    left: 0;
    width: 87%;
    height: auto;
    object-fit: contain;
}
#background-image2{
  position: absolute;
  top: 0;
  left: 0;
  width: 87%;
  height: auto;
  object-fit: contain;
}
.colors-tiroir{
  display: none;
}
.colors-content{
  display: flex;
  position: relative;
  padding: 15px 0px;
  // .colors {
  //     bottom: 12em;
  //     z-index: 3;
  //     display: flex;
  //     flex-wrap: wrap;
  //     max-width: auto;
  //     .color {
  //         margin: 0.5em;
  //         height: 22px;
  //         width: 22px;
  //         border-radius: 18px;
  //         cursor: pointer;
  //         border: 1px solid $primary-color;
  //       }
  .colors {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  
    .color {
      margin: 0.5em;
      height: 22px;
      width: 22px;
      border-radius: 18px;
      cursor: pointer;
      border: 1px solid $primary-color;
      position: relative; // Préparation pour positionner absolument le nom de la couleur
  
      &:hover {
        .color-name {
          visibility: visible; // Visible au survol
          opacity: 1;
          z-index: 1000;
          font-size: 0.7em;
          width: max-content;
        }
      }
  
      .color-name {
        position: absolute;
        bottom: 100%; // Position au-dessus de la pastille
        left: 50%; // Centre le tooltip
        transform: translateX(-50%); // Centre le tooltip horizontalement
        background-color: rgba(0, 0, 0, 0.7); // Fond semi-transparent
        color: white; // Texte blanc
        padding: 2px 8px;
        border-radius: 4px;
        visibility: hidden; // Caché par défaut
        opacity: 0;
        transition: visibility 0s, opacity 0.5s ease; // Transition douce
      }
    }
  }
  
        $colors: ("#d0f352", "#2a2b2d", "#fe251d", "#96498d",'#028790','#3f3d40','#0564c1','#921224','#fab642','#453e29', '#c0005d', '#ff0076', '#835e3d', '#c6c6c6', '#eaeaea', '#092642', '#162a27', '#5e2828', '#15174b');

        @keyframes changeColor {
          @for $i from 1 through length($colors) {
            #{percentage($i*(1/length($colors)))} {
              background-color: nth($colors, $i);
            }
          }
        }
        
    
}
.random-button {
  font-size: $size3;
  background-color: $primary-color;
  border-radius: 50px;
  color: $secondary-color;
  cursor: pointer;
  width: 91px;
  padding: 4px 18px;
  letter-spacing: 2px;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  & {
    background-image: linear-gradient(90deg, #53cbef 0%, #dcc66c 50%, #ffa3b6 75%, #53cbef 100%);
    animation: slidernbw 5s linear infinite;
    color: #000;
   }
   @keyframes slidernbw {
    to {
     background-position: 20vw;
    }
   }
}  
// styles pour le select
.config_select{
  position: relative;
  display: flex;
  width: auto;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 12px 0;
  label{
    font-weight: 600;
    min-width: 100px;
    line-height: 100%;
    font-size: $size3;
  }
  select {
    background-color: transparent;
    color: inherit;
    border: none;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    width: 100%;
    font-size: $size3;
    font-weight: 300;
  }
  .svg_select{
    position: absolute;
    right: 0;
    margin-right: 10px;
    z-index: -10;
  }

}




@media (max-width: $breakpoint-tablet) {
.colors-tiroir{
  display: initial;
}
.colors-content{
.colors {
  width: auto;
    .color {
      margin: 0.4em;
      height: 14px;
      width: 14px;
        border-radius: 18px;
      }
      button {
        margin: 0.4em;
        height: 20px;
        padding: 0px 11px;
      }  
  }
}

// styles pour le select
.config_select{
  width: auto;
  padding: 4px 0;
label{
  min-width: 60px;
  line-height: 100%;
  font-size: $size2;
  color: rgb(121, 121, 121);
}
select {
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
  padding: 7px 0;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  width: 100%;
  font-size: $size3;
}
.svg_select{
  position: absolute;
  right: 0;
  margin-right: 20px;
  z-index: -10;
}

}
}