@import '../settings';

.productDetails{
    height: 93vh;
    .productDetails_title{
        height: 7vh;
        border-bottom: 1px solid $primary-color;
        display: flex;
        align-items: center;
        padding-left: 20px;
        svg {
          margin-right: 30px;
          cursor: pointer;
          color: #000; // You can change this to suit your design.
        }
        h1{
            
            display: flex;
            align-items: center;
            height: 100%;
            text-transform: uppercase;
            font-size: $size7;
            margin: 0;
            padding-left: 94px;
        }
    }
    .productDetails_container{
        height: 86vh;
        display: flex;
        overflow-y: scroll;
        .productDetails_img{
            width: 70%;
            display: flex;
            overflow-y: scroll;
        }
        .productDetails_img img:hover {
          opacity: 1 !important;
        }
        .productDetails_img img.active {
          pointer-events: none;
        }
        
        .productDetails_config{
            display: flex;
            flex-direction: column;
            width: 30%;
            min-width: 400px;
            position: sticky;
            top: 0;
            border-left: 1px solid $primary-color;
            .productDetails_config_firstpart{
                position: relative;
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                // min-height: 80vh;
                overflow: auto;
                // justify-content: center;
            }
            .productDetails_config_name{
              .name_product_title{
                display: flex;
                h2{
                    font-weight: $font2;
                    margin: 1rem 0.4em 1.38rem 0em;
                    text-transform: uppercase;
                    font-size: $size5;
                }
              }
                p{
                  font-size: $size3;
                  font-weight: $font3;
                }
            }
            .productDetails_config_price{
                p{
                    font-size: $size5;
                    font-weight: $font5;
                }
            }
            .productDetails_config_section{
                width: 100%;
                padding: 0 0 2em 2em;
            }
                .productDetails_drawer {
                  display: flex;
                  flex-direction: column;
                  padding: 0px 1em;
                  cursor: pointer;
                  overflow: hidden;
                  height: 7vh;
                  border-bottom: 1px solid $primary-color;
                  border-top: 1px solid #000;
                  &:hover {
                    .productDetails_icon{
                      svg {
                      animation: rotate 0.2s linear;
                      } 
                    }
                  }
                  &.active {
                    height: 100%;
                    width: 100%;
                    // bottom: 320px;
                    overflow: visible;
                    position: relative;
                    background-color: white;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    z-index: 10;
                    border-top: 1px solid #000;
                  }
                  
                  .productDetails_title_tiroir {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    transition: background-color 0.3s ease;
                    padding-top: 8px;
                    .productDetails_title .productDetails_icon::before {
                      content: '';
                      display: block;
                      width: 10px;
                      height: 2px;
                      background-color: #000;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                    
                    .productDetails_title .productDetails_icon svg {
                      margin-left: 10px;
                      width: 10px;
                      height: 10px;
                      transition: transform 0.3s ease;
                    }
                    
                    .productDetails_title.active .productDetails_icon svg {
                      display: none;
                    }
                    
                    .productDetails_title.active .productDetails_icon::before {
                      width: 20px;
                    }
                    
                    h2 {
                      font-weight: $font3;
                      margin: 0;
                      font-size: $size7;
                    }
                    
                    .productDetails_icon {
                      display: flex;
                      align-items: center;
                      
                      svg {
                        margin-left: 10px;
                        width: 10px;
                        height: 10px;
                        transition: transform 0.3s ease;
                      }
                    }
                    
                    &.active {
                      background-color: #fff;
                    }
                    
                    &.active .productDetails_icon svg {
                      transform: rotate(0deg);
                    }
                  }
                  
                  
                  
                  @keyframes rotate {
                    from {
                      transform: rotate(0);
                    }
                    to {
                      transform: rotate(90deg);
                    }
                  }
                  
                  .productDetails_content {
                    height: auto;
                    padding: 1em 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    p{
                      font-size: $size3;
                      font-weight: $font3;
                    }
                    &.active {
                      opacity: 1;
                      transform: translateY(0);
                      transition-delay: 0.15s;
                    }
                  }
                }
              // }
              
        }
        
          
          
    }
    .config_bag {
      display: flex;
      align-items: center;
      padding: 1em 3em 1em 0;
      button {
          position: relative;
          font-size: $size5;
          background-color: $primary-color;
          border-radius: 50px;
          color: $secondary-color;
          cursor: pointer;
          padding: 8px 22px;
          letter-spacing: 2px;
          border: 1px solid $primary-color;
          overflow: hidden;
          transition: color 0.3s ease;
  
          &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 0%;
              background-color: #ffffff;
              transition: height 0.5s ease;
              border-radius: 50px;
              z-index: 1; /* Assure que l'effet de remplissage est derrière le texte */
          }
  
          /* Assure que le texte reste visible par dessus l'effet de remplissage */
          span {
              position: relative;
              z-index: 2; /* Plus élevé que le ::before pour rester au-dessus */
          }
  
          &:hover {
              color: #000000;
  
              &::before {
                  height: 100%;
              }
          }
      }
  }
  
  
}
.productDetails_img {
  .thumbnails {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 150px; // adjust as needed
    padding-left: 20px;
    img {
      cursor: pointer;
      width: 100%; 
      object-fit: cover;
    }
  }

  .main-image {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh;
    position: sticky;
    top: 0;
    #container {
      position: relative;
      top: 0px;
      left: 0px;
      width: 100%;
      margin: 2em auto;
      padding-bottom: 6em;
      display: flex;
      flex-direction: column;
      #product-svg {
        position: relative;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        mix-blend-mode: multiply;
        width: 100%;
        height: auto;
    }
    #background-image {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: auto;
      z-index: 1;
  }
  }
    img {
      max-width: 100%;
      max-height: 100%;
      
    }
  }
}

@media (max-width: $breakpoint-tablet) {
    .productDetails{
      height: 94vh;
        .productDetails_title{
            height: 6vh;
            padding: 0 1.9em 0 2.2em;
            display: flex;
            justify-content: space-between;
            h1{
              padding: 0;
                font-size: $size6;
            }
            svg{
              margin: 0;
            }
        }
        .productDetails_container{
          height: auto;
            flex-direction: column;
            .productDetails_img{
                height: 50%;
                width: 100%;
                border-right: 0px;
                flex-direction: column;
                .thumbnails {
                  flex: none;
                  width: 100%;
                  flex-direction: unset;
                  overflow-y: scroll;
                  max-width: unset;
                  height: 150px;
                  img{
                    width: 70%;
                    object-fit: cover;
                  }
                }
                #container {
                  width: 80%;
                  margin: 1em auto;
                  padding-bottom: 1em;
                }

              }
            
            .productDetails_config{
                position: relative;
                width: 100%;
                height: 100%;
                border-left: 0px;
                min-width: auto;
                .productDetails_config_firstpart{
                    justify-content: space-evenly;
                    width: 100%;
                    padding: 0em 1.3em;
                }
                .productDetails_config_name{
                  .name_product_title{
                    h2{
                      font-size: $size5;
                      font-weight: $font2;
                      margin: 0.6rem 0;
                  }
                }
                  p{
                    font-size: $size3;
                  }
                  
                    
                }
                .productDetails_config_price{
                    p{
                        font-size: $size3;
                        font-weight: $font3;
                        margin-bottom: 0.6rem;
                    }
                }
                .productDetails_config_color_title{
                    padding: 0 0 1em 1em;
                    p{
                        font-size: $size5;
                        color: $primary-color;
                    }
                }
                .productDetails_config_section{
                    width: 100%;
                    padding: 0 0 2em 2em;
                }
                .productDetails_config_descriptions {
                    z-index: 100;
                  }

                  .productDetails_drawer_content {
                    .productDetails_drawer {
                      padding: 0 2em;
                      height: 2em;
                      .productDetails_title_tiroir {
                        padding-top: 0px;
                        h2{
                          font-size: $size5;
                        }
                        p{
                          font-size: $size3;
                        }
                      }
                      .productDetails_content {
                        p{
                          font-size: $size3;
                          font-weight: $font3;
                        }
                      }
                    }
                  }
                  .description {
                    width: 50%;
                    font-size: $size3;
                  }
                  
                  .boite-description {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    height: 100%;
                    background-color: white;
                    transition: width 0.5s ease;
                  }
                  
                  .boite-description.show {
                    width: 100%;
                    display: flex;
                  }
                  
                  .boite-description h3 {
                    margin-top: 20px;
                  }
                  
                  .hidden {
                    display: none;
                  }
                  
                  .arrow {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background-image: url("../../assets/images/bumpak.webp");
                    background-size: cover;
                    cursor: pointer;
                  }
                  
                
            }
        }
        
        .config_bag{
            align-items: inherit;
            padding: 1em 0;
            justify-content: left;
            button{
              height: max-content;
              font-size: $size3;
              background-color: $primary-color;
              border-radius: 50px;
              color: $secondary-color;
              cursor: pointer;
              padding: 4px 14px;
              letter-spacing: 0px;
              border: none;
              box-shadow: 0px 4px 10px rgba(0,0,0,0.3);
            }
        }
        
    }   
}

// @media (max-width: $breakpoint-phone) {

//   .productDetails{
//      .productDetails_container{
//         .productDetails_config{
//           .productDetails_title_tiroir {
//           padding-top:  0px!important;
//         }
// }
// }
// }}

@media (max-height: 700px){
  .productDetails_img {
    .main-image {
      #container {
        width: 60%;
        margin: 1em auto;
        padding-bottom: 2em;
      }
    }
      }
}