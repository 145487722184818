@import '../settings';

.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7vh;
    border-bottom: 1px solid $primary-color;
    text-transform: uppercase;
    .nav_img{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 16px;
            .nav_img_text{
                padding-left: 1.4em;
                font-family:"helvetica-neue-bold-italic";
                margin-bottom: 0;
            }
          
        }
    .link{
        font-size: $size5;
        color: $primary-color;
        font-weight: 300;
        .linkPrice{
          font-size: $size5;
          align-items: center;
        }
    }
    .nav_list{
        width: -webkit-fill-available;
        width:  -moz-available;
        
        li{
            display: flex;
            justify-content: space-between;
            padding: 0 9vh;        }
    }
}
.nav_burger{
    display: none;
}
@media (max-width: $breakpoint-tablet) {
.nav {
  height: 7vh;
  position: relative;
  padding: 0 1.8em;
    .nav_img{
        display: flex;
        justify-content: space-between;
        padding-left:0;
        .nav_img_text{
          font-size: $size6;
          padding-left: 0.3em;
          font-family:"helvetica-neue-bold-italic";
          display: none;
      }
    }
    .link{
      font-size: $size7;
    }
    .nav_list{
      li{
          padding: 1vh;        }
  }
  }
  .nav_burger {
    display: flex;
    cursor: pointer;
    align-items: center;
    h2{
        margin: 0rem; 
        font-size: $size6;   
    }
    
  }
  .nav_list {
    position: absolute;
    top: 102%;
    left: 0;
    width: 100vw;
    height: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $secondary-color;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    z-index: 10;
  }
  
  .nav_list.show {
    height: 120vh; /* Hauteur maximale du menu déroulant */
    width: 100%;
  }
  
  .nav_list li {
    display: block;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: 60%;
    justify-content: center;
  }
  
  .nav_list li a {
    display: block;
    padding-left: 2rem;
    color: $secondary-color;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .nav_list li a:hover {
    background-color: $primary-color;
  }
}
.rightpart_footer{
  display: flex;
  .link{
    color: $primary-color;
    text-decoration: none;
  }
  p,a{
    padding-right: 10px;
  }
}