@import '../settings';

.page_home{
    height: 93vh;
    .page_home_content{
        height: 100%;
        display: flex;
        flex-direction: column;
        .home_bloc_left{
            height: 95%;
            width: 100%;
            img{
                height: 92vh;
                width: 100vw;
                object-fit: cover;
                padding: 9vh;
            }
        }
        
    }    
}
footer{
    padding: 0 5vw;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: $breakpoint-tablet) {

    .page_home{
        .page_home_content{
            padding: 0 3vh;
            flex-direction: column;
            .home_bloc{
                display: flex;
                flex-direction: column;
                position: inherit;
                width: 100%;
                height: 50%;
                justify-content: end;
                padding-bottom: 2em;
                .home_left,
                .home_right{
                    font-size: $size8;
                    padding-left: 0;
                }
            }
            .home_bloc_left{
                img{
                    height: 70vh;
                    width: 100%;
                    padding: 6vh 0vh;
                }
            }
            
        }    
    }
    footer{
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

}