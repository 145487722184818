@import '../settings';
.productDetails_title{
    height: 7vh;
    border-bottom: 1px solid $primary-color;
    display: flex;
    align-items: center;
    padding-left: 2em;
    h1{
        
        display: flex;
        align-items: center;
        height: 100%;
        text-transform: uppercase;
        font-size: $size7;
        margin: 0;
    }
}
.page_material{
display: flex;
flex-direction: column;
margin: auto;
padding: 2em;
}
.page_material_content{
    .material_title_eco{
        font-size: $size4;
    }
    p{
        font-size: $size4;
    }
    
}
.material_title_part{
    h2 {
        font-weight: 700;
        font-size: $size6;
        font-style: italic;
        position: relative; /* Position relative pour le titre */
        display: inline-block; 
    &::after {
        content: ''; /* Le contenu est vide mais nécessaire pour afficher le pseudo-élément */
        position: absolute; /* Positionnement absolu par rapport au titre */
        left: -20px; /* Le trait commence au début du titre */
        bottom: -10px; /* Espacement sous le titre */
        width: 110%; /* Le trait s'étend sur toute la largeur du texte du titre */
        height: 2px; /* Épaisseur du trait */
        background-color: black; /* Couleur du trait */
    }
    }
    p{
        font-size: $size4;
    }
    a{
        text-decoration:none;
        color: $primary-color;
    }
}
.color-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .elastic-part{
        img{
            padding: 4px;
        }

    }
    .color-swatch{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 100px;
            height: 100px;
            border-radius: 20px;
        }
        span{
            font-size: $size3;
        }
    }
}
@media (min-width: $breakpoint-tablet) {
    .productDetails_title{
        padding-left: 12em;
        h1{
            
            display: flex;
            align-items: center;
            height: 100%;
            text-transform: uppercase;
            font-size: $size7;
            margin: 0;
        }
    }
    .page_material{
        padding: 1em 12em;
        }
}