@import '../settings';

.productList_page{
    height: 93vh;
    .productList_title{
        height: 7vh;
        border-bottom: 1px solid $primary-color;
        h1{
            padding-left: 78px;
            display: flex;
            align-items: center;
            height: 100%;
            font-size: $size7;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
    .productList_container{
        display: flex;
        min-height: 86vh;
    .productList_container_bloc{
        width: 50%;
        border-right: 1px solid $primary-color;
        display: flex;
        flex-direction: column;
        padding-left: 181px;
        .productList_subcategory{
            h2{
                font-size: $size6;
                padding-left: 2.8em;
                font-weight: 300;
                text-transform: uppercase;
                margin: 0.4rem 0 0.2rem;
            }
        }
    }
    .productList_products{
        .product_bloc{
            display: flex;
            flex-direction: column;
            margin-left: 7.5em;
            text-transform: uppercase;
            height: 8%;
            width: fit-content;
            justify-content: center;
            :hover{
                transform: 1s ease;
                .product{
                    font-weight: bold;
                }
            }
            .product{
                text-decoration: none;
                color: $primary-color;
                font-weight: 100;
                font-size: $size4;
                margin-bottom: 0rem;
            }
            img{
                height: 50px;
                width: 50px;
                transform: 1s ease;
                transition: transform 1 ease-out;
            }
        }
    }
    .productList_img{
        width: 50%;
        max-height: 100vh;
        .productList_img_content{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .img_content_bloc{
            width: 700px;
            height: 700px;
            overflow: hidden;
            img{
                width: 600px;
                height: 600px;
                width: 100%;
                object-fit: contain;
            }
        }
        
    }
    }
    
}
@media (max-width: 842px) {
    .productList_page{
        .productList_title{
            h1{
                padding-left: 1.4em;
                font-size: $size6;
            }
        }
        .productList_container{
            flex-direction: column;
        .productList_container_bloc{
            width: 100%;
            height: 100%;
            border-right: 0px;
            padding-left: 2.3em;
            .productList_subcategory{
                h2{
                    font-size: $size5;
                    padding-left: 0em;
                }
            }
        }
        .productList_products{
            padding: 0em;
            .product_bloc{
                margin-left: 1.5em;
                .product{
                    margin-bottom: 0;
                }
            }
        }
        .productList_img{
            display: none;
        }
        }
        
    }



}